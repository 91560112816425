/* You can add global styles to this file, and also import other style files */
@use '@material/switch/switch' as mdc-switch;
@use '@material/switch/switch-theme' as mdc-switch-theme;
/* Set droplist names - -item is the currently selected font, -label is the font's appearance in the droplist*/
.ql-bubble .ql-picker.ql-font .ql-picker-label[data-value='']::before,
.ql-bubble .ql-picker.ql-font .ql-picker-item[data-value='']::before
{
  content: 'Default';
}

.ql-bubble .ql-picker.ql-font .ql-picker-label[data-value='times-new-roman']::before,
.ql-bubble .ql-picker.ql-font .ql-picker-item[data-value='times-new-roman']::before
{
  content: 'Times New Roman';
  font-family: 'Times New Roman';
}

.ql-bubble .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
.ql-bubble .ql-picker.ql-font .ql-picker-item[data-value='arial']::before
{
  content: 'Arial';
  font-family: 'Arial';
}

.ql-bubble .ql-picker.ql-font .ql-picker-label[data-value='Roboto']::before,
.ql-bubble .ql-picker.ql-font .ql-picker-item[data-value='Roboto']::before
{
  content: 'Roboto';
  font-family: 'Roboto';
}

.ql-bubble .ql-picker.ql-font .ql-picker-label[data-value='cursive']::before,
.ql-bubble .ql-picker.ql-font .ql-picker-item[data-value='cursive']::before
{
  content: 'cursive';
  font-family: 'cursive';
}

.ql-bubble .ql-picker.ql-font .ql-picker-label[data-value='fantasy']::before,
.ql-bubble .ql-picker.ql-font .ql-picker-item[data-value='fantasy']::before
{
  content: 'fantasy';
  font-family: 'fantasy';
}

.ql-bubble .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-bubble .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before
{
  content: 'monospace';
  font-family: 'monospace';
}

.ql-bubble .ql-picker.ql-font .ql-picker-label[data-value='sans-serif']::before,
.ql-bubble .ql-picker.ql-font .ql-picker-item[data-value='sans-serif']::before
{
  content: 'sans-serif';
  font-family: 'sans-serif';
}

.ql-bubble .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-bubble .ql-picker.ql-font .ql-picker-item[data-value='serif']::before
{
  content: 'serif';
  font-family: 'serif';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='']::before
{
  content: 'Default';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times-new-roman']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times-new-roman']::before
{
  content: 'Times New Roman';
  font-family: 'Times New Roman';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before
{
  content: 'Arial';
  font-family: 'Arial';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Roboto']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Roboto']::before
{
  content: 'Roboto';
  font-family: 'Roboto';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='cursive']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='cursive']::before
{
  content: 'cursive';
  font-family: 'cursive';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='fantasy']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='fantasy']::before
{
  content: 'fantasy';
  font-family: 'fantasy';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before
{
  content: 'monospace';
  font-family: 'monospace';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='sans-serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='sans-serif']::before
{
  content: 'sans-serif';
  font-family: 'sans-serif';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before
{
  content: 'serif';
  font-family: 'serif';
}

/****************************************************
Set the font-family content used for the HTML content.
*****************************************************/
.ql-font-arial {
  font-family: 'Arial';
}

mux-player{
  aspect-ratio:16/9;
  display: block !important;
  border-radius: 4rem !important;
  }

.ql-font-times-new-roman {
  font-family: 'Times New Roman';
}
.cursor-styles
{
 cursor: pointer;
 z-index: 9999;
}
.ql-font-Roboto {
  font-family: 'Roboto';
}
.ql-font-cursive {
  font-family: cursive;
}
.ql-font-fantasy {
  font-family: fantasy;
}
.ql-font-monospace {
  font-family: 'monospace';
}
.ql-font-sans-serif {
  font-family: 'sans-serif';
}
.ql-font-serif {
  font-family: 'serif';
}

.form-block .form-control {
  font-size: 11px !important;
}

blockquote {
  background: #f9f9f9;
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.story-item__detail-more .btn-white:hover {
  color: black !important;
}

/**  Start - Loading icon - Source loading.io **/
.fr-view {
  height: max-content !important;
}

.ql-mention-list-container {
  max-height: 250px !important;
  margin-top: 10px;
}

@media (max-width: 578px) {
  .ql-mention-list-conainer {
    margin-left: 30px;
  }
}

.ql-mention-list-container::-webkit-scrollbar {
  width: 0px;
}

.mention {
  background-color: #F5F5F5;
}

.ql-editor {
  line-height: 1.5 !important;
}

.request-story {
  padding: 0 2rem !important;
}

.embed-form-disabled .form-control:disabled, .form-control[readonly] {
  cursor: text;
}

.fixed-storyteller-btn {
  position: fixed;
  right: 20px;
  bottom: 45px;
  background: white;
  border-radius:5px;

  .btn.btn-outline {
      margin: 0;
  }
}

.reducedHeight {
  height: calc(100% - 150px) !important;
  overflow: auto !important;
}

.request-story .story-flow-view__box {
  position: initial !important;
  height: 35vh;
  box-shadow: none;
  min-height: 35vh;
  /*border: 1px solid #ced4da;*/
  border: none !important;
  border-radius: 0.25rem;
}

.request-story .view-box-people {
  padding: 1em 0em .5em 0em !important;
}

.ps-content, .story-flow__wrapper {
  height: 100%;
}

.story-flow-view__content {
  // height: 78%;
  height: 75%;
  overflow: auto;
}
.story-flow-detail .ql-editor{
  width: 90% !important;
}
.story-flow-finished {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.ql-bubble .ql-tooltip {
  width: auto !important;
  left: auto !important;
}

.ql-tooltip-arrow {
  display: none !important;
}

.story-flow-view__content::-webkit-scrollbar, .m-story-tags::-webkit-scrollbar, .story-flow-finished::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.story-flow-view__content::-webkit-scrollbar-track, .m-story-tags::-webkit-scrollbar-track, .story-flow-finished::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.story-flow-view__content::-webkit-scrollbar-thumb, .m-story-tags::-webkit-scrollbar-thumb, .story-flow-finished::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.story-flow-view__content::-webkit-scrollbar-thumb:hover, .m-story-tags::-webkit-scrollbar-thumb:hover, .story-flow-finished::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.m-story-tags {
  height: 100%;
  overflow: auto;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 16px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #c2cacd;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
   /* Iframe layout  starts*/
    @media (min-width: 899px) {
      .col-lg-6 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
    }
  
    @media (min-width: 899px) and (max-width: 990px) {
      .story-flow-post {
        margin-left: 0rem !important;
      }
  
      .story-flow-view__box{
        right: -26px !important;
      }
      .menu_display {
        position: absolute !important;
        left: 5rem !important;
      }

      .spacer{
        margin-top: -2rem !important;
      }

      .show__tagscount{
        display: none !important;
      }
  
  
      .storyflow-progress-preview-container {
        width: 5% !important;
        height: 84% !important;
        visibility: hidden !important;
      }
  
      .story-flow-view__box {
        right: 0px;
        width: 92% !important;
        top: 1.8rem !important;
      }
  
      .view-box-tags__title {
        font-size: 1.5rem !important;
        line-height: 1.28571 !important;
      }
  
      .view-box-tags__title__sub {
        font-size: 1rem !important;
      }
    }
  
    @media (min-width: 899px) {
      .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
      }
    }

  /* Iframe layout ends */
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }

  .lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  
  .remark-box--mobile{
    z-index: 9 !important;
  }
  .meesage__remarkbox__mobile{
    z-index: 9 !important;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
/**  End - Loading icon - Source loading.io **/
  
.story-edit-boost-box{
    .hidden{
        display: none !important
    }
    .tag-item{
        display: none !important;
        &.show-me {
            display: inline !important;
        }
    }

    span.lock-tags{
        display: none !important;
    }
}



/** Froala config **/

.fr-box .fr-counter {
  display: none;
}

/** Style for integration page **/

.slack {
  background-color: #4a144b !important;
}

.yammer {
  background-color: #1a62a8 !important;
}

.chatter {
  background-color: #279ee0 !important;
}

.testimonial_button {
  background-color: #78d37c !important;
  cursor: pointer;
}

.integration_card {
  cursor: pointer;
  color: white;
}

.integration_logo {
  text-align: center;
}

.integration_logo img {
  max-height: 80px;
}

// style for dynamic comp icons

.check {
  color: #78d37c;
}

.times {
  color: #b9b9b9;
}

.btn-create {
  font-size: 0.875rem;
}

.pipeRecordRTC {
  border-radius: 0 !important;
}

#pipeClickPowered-gs-recorder {
  display: none;
}

#pipeMenu-gs-recorder {
  visibility: hidden;
}

.m-story--list .without-list {
  display: none !important;
}

.m-story--list .with-list {
  display: block !important;
}

.btn-publish-text {
  margin-right: 0 !important;
  width: 102px;
  //border-right: 2px solid white;
  border: 1px solid white;
  // border-radius: 7px 0 0 7px !important;
}

.btn-publish-text_inbound {
  margin-right: 0 !important;
  width: 102px;
  //border-right: 2px solid white;
  border: 1px solid white;
  border-radius: 5px !important;
}

.ql-editor.ql-blank::before {
  // line-height: 1.125em;
  font-weight: 100;
  font-style: normal;
  left: 3px !important;
  font-size: 1.25rem !important;
  // color: #cacdd5;
}

.capmCustomClass .ql-editor.ql-blank::before {
  line-height: normal;
  font-weight: 100;
  font-style: normal;
  color: #8c8c8c;
  font-size: 1.35rem;
  left: 0;
  font-family: Helvetica, Arial, sans-serif;
}

.capmCustomClass .ql-editor.ql-blank {
  font-size: 1.35rem;
}

.ql-editor {
  padding: 0px !important;
}

html {
  font-size: 85%;
}
@media (max-width: 700px) {

  .hide-board_tags{
    display: none !important;
  }
 }
.mouse_pointer{
  cursor: pointer !important;
}
.create-story-title-custom, .create-story-title-custom text-area-auto-height-control textarea {
  font-size: 1.5rem !important;
  font-weight: 900;
}
.titleplace, .titleplace text-area-auto-height-control textarea::placeholder {
  // color:#f40b5f !important;
  animation: pulse 2s infinite;
}

.fullname__text.form-group .fullname__text.form-group gs-input-material .form-label-group .form-control{
  width: 50% !important;
}
.email__text.form-group  .email__text.form-group  gs-input-material .form-label-group .form-control {
  width: 50% !important;
}

@keyframes pulse {
  0% {
   color: #cf2f6a !important;
  }
  75% {
    color: #eb5b8f !important;
  }
  100% {
    color: #f17ba6 !important;
  }
}

/* quill text story create */

.ql-bubble  {
  font-size: 1.25rem !important;
  padding-top: 12px !important;
}

.ql-editor.ql-blank::before {
  font-size: 1.25rem !important;
}


@media (max-width: 768px) {
  .create-story-title-custom, .create-story-title-custom text-area-auto-height-control textarea {
    font-size: 1.5rem !important;
    font-weight: 900;
  }

  .ql-bubble  {
    font-size: 1.25rem !important;
    padding-top: 12px !important;
  }

  .ql-editor.ql-blank::before {
    font-size: 1.25rem !important;
  }

}

.ql-editor h2 {
  font-size: 1.5rem !important;
  font-weight: 500;
}

.m-story-content__content-post h2 {
  font-family: Helvetica, Arial, sans-serif !important;
}

.ql-container.ql-bubble:not(.ql-disabled) a {
  white-space: normal !important;
}

.ql-bubble .ql-tooltip {
  border-radius: 8px !important;
}

particle {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
}

.copyandshare{
  height: 37px;
  min-width: 130px;
  position: fixed;
  text-align: center;
  margin-bottom: 0rem;
  bottom: 27px;
  /* right: 47rem; */
  left: 15rem;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  background: transparent;
  font-size: 1rem;
  color:#37474f;
  font-weight: 500;
  z-index: 99;
  cursor: pointer;
  border: 1px solid #ebebec;
  line-height: 1.6rem;
}

.headerCopyRequest{
  display: inline-block !important;
  border: 1px solid #ebebec;
  height: 1.875rem !important;
  padding: 0rem 0.5rem !important;
  border-color: #60aeff;
  background-color: #fff;
  border-radius: 5px !important;
  line-height: 1.75rem !important;
  color: #60aeff !important;
  margin-top: 0.99rem;
  text-align: center;
  width: 6rem;
}

.CreateCampaign{
  display: inline-block !important;
  border: 1px solid #ebebec;
  height: 1.875rem !important;
  padding: 0rem 0.5rem !important;
  border-color: #60aeff;
  background-color: #fff;
  border-radius: 5px !important;
  //line-height: 1.75rem !important;
  color: #60aeff !important;
  //margin-top: 0.99rem;
  text-align: center;
  width: auto;
}

.CreateCampaign:hover{
  background-color: #f0f7ff;
  cursor: pointer !important;

}

.approvalrequest{
  height: 26px;
  width: 130px;
  //border: 1px solid #25c649;
  border-radius: 5px;
  color: #37474f;
  text-align: center;
  padding-top: 5px;
  margin: 0 5px;
  white-space: nowrap;
  cursor:pointer !important;
  transition: 0.4s;
  font-weight: 500;
}

.approvalrequest_btn {
  border: 1px solid #25c649;
  width: 150px !important;
  background: #fff !important;
  border: 1px solid #ebebec;
  color: #37474f;
}

@media (max-width:980px){
  .approvalrequest_btn{
    display: none;
  }
}

@media (max-width:480px){
  .approvalrequest_btn{
    display: none;
  }
}

@media (max-width:480px){
  .approval-bar{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width:480px){
  .approvalrequest{
    height: 25px;
    width: 100%;
    padding-top: 0;
    justify-content: center;
  }
}



.approvalrequest_btn:hover{
  background: #ebebec !important;
}

.checkPublic{
  position: static;
  left: 10rem;
  margin: right 30px;
  border: unset !important;
}
.text_approval{
  margin-right: 5px;
  font-weight: 500;
}

.copyandsharerequest{
  height: 37px;
  position: fixed;
  min-width: 130px;
  margin-bottom: 0rem;
  bottom: 27px;
  /* right: 47rem; */
  left: 16rem;
  text-align: center;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  background: transparent;
  font-size: 1rem;
  color: #37474f;
  font-weight: 500;
  z-index: 99;
  cursor: pointer;
  border: 1px solid #ebebec;
  transition: 0.4s;
  line-height: 1.6rem;
}

.copyandshare:hover{
  background-color: #ebebec;
  cursor: pointer !important;

}

.headerCopyRequest:hover{
  background-color: #f0f7ff;
  cursor: pointer !important;
}

.copyandsharerequest:hover{
  background-color: #ebebec;
  cursor: pointer !important;
}

// .eLqjMr {
//   width: 100%;
//   height: 27%;
//   margin-top: 0px;
//   /*border: 1px solid #fc6076 !important;*/
//   border-radius: 8px;
//   /*box-shadow: rgba(221, 28, 144, 0.25) 0px 0px 0px 4px !important;*/
// }

.fVfnsp .hv-main-button:hover {
  transform: none;
  box-shadow: none;
}
.sc-cuOiQm .fVfnsp{
  color: #37474f;
  border-color: #b0bec5;
  border-radius: 6px;
  border-width: 1px;
}
.fVfnsp{
  color: black !important;}

// .bxDjfq{
//   background-color: #fff;
// }

// .jnmbkb {
//   color:black !important}

// .bxDjfq{
//   background-color: #fff !important;
// }
// .bCfmAc {
//   background: #f17ba6 !important;
// }

// .xzrpV .hv-main-button{
//   background: #fc6076 !important;
// }
  .mat-checked .mat-slide-toggle-thumb{
    background-color: #5bc2c6 !important;
} 

.mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: #5bc2c6 !important;
}


 .mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
    background-color: #96C8CB !important;
    }
  
    .mat-slide-toggle-thumb-container {
      width: 24px !important;
      height: 24px !important;
    }
  
    .mat-slide-toggle-thumb{
    height: 24px !important;
    width: 24px !important;
    border-radius: 50%;
  }
  .mat-slide-toggle-bar {
    position: relative;
    width: 36px !important;
    height: 18px !important;
    flex-shrink: 0;
    border-radius: 8px;
}

////////betastyles
#hvRecorderSdkContainer{
  background-color: #fff !important;
  border-radius: 12px !important;
}


.isnUmz {
  background: none !important;
  border: none !important;
  justify-content: space-evenly !important;
  padding: 0px !important;
}

/* borders around video hippo */

.kJSHIO {
  padding-bottom: 10px !important;
}

.eOqBYR {
  padding-bottom: 10px !important;
}

.jZgUxF {
  border-radius: 12px !important;
}

.fROSsC {
  border-radius:0px 0px 12px 12px !important;
}

.fNAjsg {
  border-radius: 12px !important;
}

.bVqIix {
  border-radius: 12px !important;
}

.kbBFbn {
  border-radius: 12px !important;
}

.eIYKdo label {
  //font-size: 20px !important;//
  //font-weight: 900 !important;//
  //padding-bottom: 10px !important;//
  //padding-top: 10px !important;//
  display: none !important;
}

.eIYKdo {
  padding: 30px 30px 0 30px !important;
}

 .medium-6 {
  width: 100% !important;
  //padding: 15px !important;
}

.dHwRMj {
  padding-left: 20px !important;
}


.bfPAsk {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
}

 .embedCustomThumbnailCardWrapper .embedCustomThumbnailCards h4 {
  font-size: 1.2rem !important;
  font-weight: 900 !important;
  color: Black !important;
}

 .embedCustomThumbnailCardWrapper .embedCustomThumbnailCards p {
  color: #37474f !important;
  font-size: 1rem !important;
}

 .thumbnailNote {
  display: none !important;
}

.hHOtfb#importThumbnail {
  border-radius: 12px 12px 0px 0px !important;
}

.hHOtfb#importThumbnail .import-body {
  border-bottom: 1px solid transparent !important;
}

.hHOtfb#importThumbnail .import-media-container {
  padding: 30px !important;
}

.hHOtfb#importThumbnail .import-media-container .tab-container {
  border-bottom: 1px solid transparent !important;
  padding: 30px 10px;}

.hHOtfb#importThumbnail .import-footer .foot-note {
  text-align: left !important;
  color: white !important;
}

.hHOtfb#importThumbnail .import-media-container .tab-container .tab {
  color: white !important;
  font-size: 16px !important;
  font-weight: 900 !important;
}

.hHOtfb#importThumbnail {
  background-color: white !important;
}


.ehroOE{
   font-size: 13px !important;
}

.hHOtfb#importThumbnail .wiz-primary-btn {
  border-radius: 6px !important;
  padding: 10px 15px !important;
  font-family: "Open Sans" !important;
  font-weight: 600 !important;
  font-size: 0.8125rem !important;
  background-color: #f47a59 !important;
  background-image: none !important;
}

// .hHOtfb#importThumbnail .wiz-new-secondary-btn {
//   border-radius: 6px !important;
//   padding: 10px 15px !important;
//   font-family: "Open Sans" !important;
//   font-weight: 600 !important;
// }

 .thumbnailImage {
  aspect-ratio: auto !important;
  margin: 0px auto !important;
}

#hvRecorderSdkContainer .gsgubV .hvrecorder-helptext{
  display: none !important;
}
#hvRecorderSdkContainer .bkZTuX {
  visibility: hidden !important;
  z-index: -1 !important;
  pointer-events: none !important;
}

p.drive-text {
  color: #181d27 !important;
  padding-bottom: 5px !important;
  //display: none !important;
}

.hHOtfb#importThumbnail .import-media-container .import-body-container .image-gallery .image .add-icon {
  transform: none !important;
  width: 60px !important;
  color: white !important;
  padding-top: 20px !important;
}

.sc-cTTdyq.hHOtfb #importThumbnail {
  background-color: white !important;
}



@media (max-width:680px){
  #hvRecorderSdkContainer{
    width: 100% !important;
    min-width: unset !important;
    border-radius: 0px !important; 
    background-color: #fff !important;
    height: 100% !important;
  }

  .trim-preview-container{
    display: none !important;
  }

  .se-crop-highlighter {
    background: black !important;
  }

  .ruler-static {
    display: none !important;
  }

  .nDlVW {
    justify-content: flex-start !important;
  }

  .jaPbCU {
    height: 50% !important;
  }

  .kAQcrD {
    top: 49% !important;
  }

  .hliLke {
    width: 40px !important;
    height: 40px !important;
    border: 1px solid white !important;
    filter: none !important;

  }

  .enTuqa {
    height: 100% !important;
  }

  .fmTwjL {
    width: 100% !important;
    justify-content: space-evenly !important;
  }

  .lgMLJO {
    border-radius: 0px !important;
    //height: 450px !important;
    background-color: black !important;
  }

  .fDSPE {
    border-radius: 0px !important;
    height: 10% !important;
    margin-bottom: 20px !important;
  }

  .iubhNT {
    border-radius: 0px !important;
  }

  .jaYIOa {
    width: 91.8% !important;
    height: 52.82% !important;
  }

  .hv-sdk-preview-close.fqMOgy{
    position: relative !important;
    right: 45px !important;
    z-index: 99 !important;
    top: 5px !important;
  }
   
  .cNntwp .hv-btn-dropdown-menu{
    font-size: 12px !important;
  }

  .hTMsXA {
    width: 20% !important;
  }

  .netTJ {
    width: 100% !important;
    height: 60% !important;
  }

  .netTJ::before {
    border-color: white !important;
  }

  .jRehzA {
    width: 100% !important;
  }

  .dunjYr {
    min-width: 36px !important;
    min-height: 36px !important;
  }

  .kUoZCo {
    width: 25% !important;
  }

  .eVhKXX {
    width: 47% !important;
    justify-content: space-between !important;
  }

  .cHgmHB {
    width: 45% !important;
    border-radius: 50% !important;
  }

  .dalyOc {
    width: 50% !important;
  }

  .gwvaVb {
    width: 20% !important;
  }

  .gwvaWp {
    width: 20% !important;
  }

  .gweBIo {
    width: 100% !important;
    margin: 10px auto 0px !important;

  }

  .gweBIo .hippo-video-player {
    border-radius: 0px !important;
  }

  .iIvYLb {
    width: 100% !important;
    margin: 10px auto 0px !important;
  }

  .screen-enter-done {
    margin-top: 50% !important;
  }
}



.cNntwp .hv-btn-dropdown-select img {
  position: absolute !important;
  right: 0px !important;
  top: 57% !important;
}


.Hcmbh {
  font-size: 14px !important;
}
.cNntwp .hv-btn-dropdown-select{
  padding: 7px 12px 5px 8px !important;
}

.jnPclX {
  font-size: 14px !important;
}
.cNntwp .hv-btn-dropdown-select img{
  right: 0px !important;
  top: 57% !important;
}
.hvplayer-wrapper .player-controls-container{
  z-index: -1 !important;
}

.eviQde{
  color: rgb(179, 184, 193) !important;
}
.eWfYLX{
  background-color: #fff !important;
}

.hv-sdk-submit-btn .hv-main-button {
  background-color: #fc6076 !important;
}

.hv-sdk-recorder-edit-btn .hv-main-button{
  border-color: #63636529 !important;
}
.hv-sdk-close-btn .hv-main-button{
  border-color: #63636529 !important;
  color: rgb(179, 184, 193);
}
.hv-sdk-edit-save-btn .hv-main-button{
  background-color: #fc6076 !important;
}
.hv-cancel-record-modal-confirm-option .hv-main-button{
  background-color: #fc6076 !important;
}
.hv-sdk-import-container .hv-sdk-upload-status-text{
  color:#fc6076 !important; ;
}
.hv-sdk-import-container .hv-sdk-upload-status-type{
  color:#fc6076 !important;
}
.hv-sdk-import-container .hv-sdk-upload-status-time{
  color:#fc6076 !important;
}

#hvRecSdkUploadProgress .hv-sdk-upload-status-time{
  color:#fc6076 !important;
}
.hv-new-player .volume-container .volume-slider .ui-slider-range{
  background-color: #fc6076 !important;
}

 .hv-sdk-upload-status-text{
  color:#fc6076 !important;
 }
 .hv-new-player .volume-container .volume-slider .ui-slider-handle{
  background-color: #fc6076 !important;
 }

 .sc-kKQOHL .itfmPY .hv-sdk-status-filler{
  background-color: #fc6076 !important;
 }



.sc-fTJzqL.cFcfdE .hv-main-button{
  display: none;
  border-color: #63636529 !important;
  color: rgb(179, 184, 193) !important;
}

#hvRecSdkUploadProgress{
  background-color: #fff;
}

.sc-kGFDUS .dfzUod{
  background-color: #fff !important;
}
.gCRZXp{
  background-color: #fff !important;
}
.gcUnM{
  background-color: #fc6076 !important; ;
}
.sc-kGFDUS .dfzUod{
  background-color: #fff !important;
}
.player-controls-outside .player-progress .ui-slider-handle, .player-controls-outside .player-progress input{
  background-color: #fc6076 !important;
}
#hvRecorderSdkContainer .ui-slider-range{
  background-color: #fc6076 !important;
}
#hvRecorderSdkPreview{
  background-color: #fff !important
}
// sc-jZOaZb iGctpj hv-sdk-upload-status-text
.hv-sdk-status-filler{
  background-color: #fc6076 !important;
}

.sc-kKQOHL .itfmPY .hv-sdk-status-filler{
  background-color: #fc6076 !important;
}
.hv-sdk-import-container{
  background-color: #fff !important;
}

.eTqAKA{
  color: rgb(179, 184, 193) !important;
}
.thumbnailButtonSkeleton {
  // display: flex;
  margin: 0px 5px;
  // -webkit-box-align: center;
  align-items: center;
  padding: 10px 16px;
  border-style: solid;
  border-width: 1px;
  text-transform: uppercase;
  cursor: pointer;
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
  font-size: 12px !important;
  letter-spacing: 0px;
  border-radius: 8px;
  transition: transform 0.2s ease 0s, 
  box-shadow 0.2s ease 0s;
  user-select: none;
  border-color: #63636529;
}


#hvRecorderSdkContainer{
  .hv-import-widget-view {
    background-color: #fff;
  }
  
  #importThumbnail{
    // .pull-right {
    //   display: flex;
    //   float: right;
    // }
    #cancelImport {
      @extend .thumbnailButtonSkeleton;
      background-color: transparent !important;
      color: #b3b8c1 !important;
    }
    #import {
      @extend .thumbnailButtonSkeleton;
      background: #fc6076;
      color: #fff;
    }
    #driveAccessBtn{
      @extend .thumbnailButtonSkeleton;
      background: #fc6076;
      color: #fff;
    }
  }

  .sc-camqpD.fwrflD .hv-main-button {
    color: #b3b8c1;
  }
}

$_color-selected-handle: #5bc2c6;
$_color-unselected-handle: white;

$_color-unselected-track: #00000061;
$_color-selected-track: #96c8cb;
$_color-disabled-selected-track: $_color-selected-track;
$_color-disabled-unselected-track: $_color-unselected-track;

.mat-mdc-slide-toggle {
  .mdc-switch {
    @include mdc-switch-theme.theme((disabled-selected-handle-color: $_color-selected-handle,
        disabled-unselected-handle-color: $_color-unselected-handle,
        selected-handle-color: $_color-selected-handle,
        selected-focus-handle-color: $_color-selected-handle,
        selected-hover-handle-color: $_color-selected-handle,
        selected-pressed-handle-color: $_color-selected-handle,
        unselected-handle-color: $_color-unselected-handle,
        unselected-focus-handle-color: $_color-unselected-handle,
        unselected-hover-handle-color: $_color-unselected-handle,
        unselected-pressed-handle-color: $_color-unselected-handle,
        disabled-selected-track-color: $_color-selected-track,
        disabled-unselected-track-color: $_color-unselected-track,
        selected-track-color: $_color-selected-track,
        selected-focus-track-color: $_color-selected-track,
        selected-hover-track-color: $_color-selected-track,
        selected-pressed-track-color: $_color-selected-track,
        unselected-track-color: $_color-unselected-track,
        unselected-focus-track-color: $_color-unselected-track,
        unselected-hover-track-color: $_color-unselected-track,
        unselected-pressed-track-color: $_color-unselected-track,
        disabled-selected-icon-color: $_color-selected-handle,
        disabled-unselected-icon-color: $_color-unselected-handle,
        selected-icon-color: $_color-selected-handle,
        unselected-icon-color: $_color-unselected-handle,
        selected-hover-state-layer-color: $_color-selected-handle,
        selected-focus-state-layer-color: $_color-selected-handle,
        selected-pressed-state-layer-color: $_color-selected-handle,

      ));
    --mdc-switch-handle-height: 24px;
    --mdc-switch-handle-shape: 50%;
    --mdc-switch-handle-width: 24px;
    --mdc-switch-selected-icon-size: 0px;
    --mdc-switch-track-height: 18px;
    --mdc-switch-track-shape: 8px;
    --mdc-switch-track-width: 36px;
    --mdc-switch-unselected-icon-size: 0px;
    --mdc-switch-state-layer-size: 0px;
  }
}
media-poster-image{
  background: #000 !important;
  }

  mux-player::part(poster-layer) {
    background: #000 !important;
  }





.media_weapper{
  bottom: 9rem;
  // position: fixed;
  left: 19.5rem;
  z-index: 99;
}
 
.camera_weapper{
  bottom: 9rem;
  // position: fixed;
  left: 26.4rem;
  z-index: 99;
}

.voicetext_button {
  height: 3rem;
  width: 3rem;
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: 0.3s;
  margin-left:0.5rem;
}

.voicetext_button:hover{
  background-color: #e0e0e0;
  cursor: pointer;
}

.voicetext_wrapper {
  bottom: 9rem;
  // position: fixed;
  left: 16rem;
  z-index: 99;
}
.image_weapper{
  // position: fixed;
  bottom: 9rem;
  left: 23rem;
  z-index: 99;
}

.detail_steps_bar{
  // position: relative;
  // left: 2rem;
  margin-right: 5rem;
  margin-left: 1rem;
  width: 17%;
}
.contributor_container{
  width: 325px;
}
.separator{
  position: absolute;
  background:#e0e0e0;
  width: 100%;
  height: 1px;
  bottom: 7rem;
}

.menu_display{
  position: absolute;
  bottom: 8rem;
  left: 14rem;
}

.ql-bubble .ql-tooltip{
  z-index: 9999;
}

.preview .mat-expansion-panel{
  overflow: unset !important;
}


@media (max-width: 899px)
{
  .mobile_dispaly{
    display:block !important; 
  }
  .desktop_display{
    display: none;

  }
}


.loader_body_detail{
  text-align: center;
  position: relative;
  position: relative;
  top: 9rem;
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0; */
  font-size: 23px;
  color: #000;
  font-weight: 800;
}


.mobile_dispaly{
  display:none; 
}
.desktop_display{
  display: block;
}

.checkoutborad gs-select-material{
  .form-label-group select{
    padding-top: 1.3rem !important;
  }
  .form-label-group.force-focus label {
    font-size: 1em !important;
  }
}

.highlight_my_approval{
  border-radius: 5px !important;
  background-color: #60aeff !important;
  color: #fff !important;
}

.disable_All_Actions{
  pointer-events: none !important
}

.carry_on{
  position: absolute;
    right: 1rem;
    top: -0.8rem;
}

.disabledDraftButton{
  border-color: #D2D2D2 !important;
  background-color: #fff !important;
  color: #ABABAB !important;
 pointer-events: none !important;
}

// set font size in Quill modal toolbar
.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  content: 'Small';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=medium]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=medium]::before {
  content: 'Medium';
}
.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  content: 'Large';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=extralarge]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=extralarge]::before {
  content: 'Extra Large';
}
.ql-bubble .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  content: 'Huge';
}
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  font-size: 10px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=medium]::before {
  font-size: 16px;
}
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  font-size: 18px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=extralarge]::before {
  font-size: 24px;
}
.ql-bubble .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  font-size: 32px;
}



.ql-editor .ql-size-small {
  font-size: 0.75em;
}
.ql-editor .ql-size-medium {
  font-size: 1.176em;
}
.ql-editor .ql-size-large {
  font-size: 1.324rem;
}
.ql-editor .ql-size-extralarge {
  font-size: 1.765rem;
}
.ql-editor .ql-size-huge {
  font-size: 2.353rem;
}



.private_page{
  .b-information{
    background: #FAFAFC !important;
    padding-bottom: 1.875rem !important;
    overflow: hidden !important;
  }
  .b-information > .container {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}
}

.public_page{
  .b-information{
    background: #FAFAFC !important;
    padding-bottom: 1.875rem !important;
    overflow: hidden !important;
  }
  .b-information > .container {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}
}
.tag_container{
  display: flex;
  justify-content: center;
  margin-left: 9rem;
  margin-right: 9rem;
}

.remove_margin{
  margin: 0rem !important;
}
rendley-video-editor{

    > .timeline__void-container{
      margin-top: 0px !important;
    }
    &.compositon{
      background-color: red !important;
    }
    & > .compositon{
      background-color: green !important;
    }

    .compositon{
      background-color: blue !important;
    }

}

.w-51{
  width: 51% !important;
}

.disablesubmitcontainer{
  // background: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
    opacity: 1;
}

@import 'assets/front-end-minify/css/vendor.min.css';
@import 'assets/front-end-minify/css/style.css';
//@import '~video.js/dist/video-js.css';
//@import '~videojs-record/dist/css/videojs.record.css';
@import 'tui-image-editor/dist/tui-image-editor.min.css';
@import '@ctrl/ngx-emoji-mart/picker';
@import 'cropperjs/dist/cropper.min.css';
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';
// @import "~owl.carousel/dist/assets/owl.carousel.css";
// @import "~owl.carousel/dist/assets/owl.theme.default.css";
@import 'daterangepicker/daterangepicker.css';

@import '~quill/dist/quill.core.css';
 @import '~quill/dist/quill.bubble.css';
 @import '~quill/dist/quill.snow.css';
 @import "~quill-mention/dist/quill.mention.css";
 @import "~@ng-select/ng-select/themes/default.theme.css";


// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
